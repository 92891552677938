import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ColumnAlternatingStyled from '../components/columnAlternatingStyled'
import CardNews from '../components/cardNews'
import moment from 'moment'

const NewsPageRoute = (props) =>  {
  const news = props.data.allContentfulNews.edges
  const featured = props.data.featuredNews?.edges[0]?.node
  const heroImage = props.data.heroImage

  const { pagePath } = props.pageContext
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const getPaginationPages = numPages => {
    let content = []
    for (let i = 1; i <= numPages; i++) {
      content.push(<option key={i} value={i} aria-label={`Go to page ${i}`} aria-current="false" >{i}</option>)
    }
    return content
  }

  const getPaginationLinks = numPages => {
    let content = []
    for (let i = 1; i <= numPages; i++) {
      if (currentPage !== i) {
        content.push(<a href={i == 1 ? `/news/` : `/news/page/${i}/`} key={i}>{`News: Page ${i}`}</a>)
      }
    }
    return content
  }

  const changePage = e => {
    navigate(
      e.target.value && e.target.value !== "1"
        ? `${pagePath}${e.target.value}/`
        : `/news/`
    )
  }

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-50"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="CICS.com news"
          subtitle="News from CICS.com and across other divisions of UNICOM Global."
          ctaslug={currentPage === 1 ? "/rss.xml" : null}
          ctatext={currentPage === 1 ? "RSS-Feed" : null}
          type="news"
        />

        <main>
          <section id="main" className="bg-white pt-4">
            <MDBContainer>
              {currentPage === 1 &&
              // Render page 1 with featured item card first 
              <div className="py-5">
                {featured && 
                  <ColumnAlternatingStyled
                    textCol="7"
                    imageCol="5"
                    title={`${featured.title}`}
                    subtitle={null}
                    subtitlecolour={null}
                    description={featured.subtitle}
                    image={featured.image.localFile.childImageSharp.gatsbyImageData}
                    alt={featured.title}
                    placement="right"
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                    link={`/news/${featured.slug}/`}
                    date={`Featured article: ${moment(featured.date).format('YYYY/MM/DD')}`} 
                    author={null}
                    readtime={null}   
                  />
                }
              </div>
              }
                
              <MDBRow className="pt-5">
                {news.map(({ node }, index) => (
                  <CardNews item={node} newspaper={props.data.newspaper} key={index} />
                ))}
              </MDBRow>

              <MDBRow className="pagination pt-4 mt-5">
                {!isFirst && (
                  <MDBCol className="text-left">
                    <Link to={currentPage === 2 ? `/news/` : `${pagePath}${prevPage}/`} rel="prev" className="linkedin">
                      ← Newer news
                    </Link>
                  </MDBCol>
                )}
                {!isLast && (
                  <MDBCol className="text-right">
                    <Link to={`${pagePath}${nextPage}/`} rel="next" className="linkedin">
                      Older news →
                    </Link>
                  </MDBCol>
                )}
              </MDBRow>

              <MDBRow className="pt-3">
                <MDBCol className="text-right">
                  <span>Showing page </span> 
                  <select 
                    aria-label="Pagination dropdown"
                    value={currentPage === 1 ? `` : currentPage.toString()}
                    onChange={changePage}
                  >
                    {getPaginationPages(numPages)}
                  </select>
                  <span> of {numPages}</span>
                </MDBCol>
              </MDBRow>
              
              <div style={{display: "none"}}>
                {getPaginationLinks(numPages)}
              </div>                  
            </MDBContainer>
          </section>
        </main>
      </div>
    </Layout>
  )
}
export default NewsPageRoute

export function Head( {data, location, pageContext} ) {
  const heroImage = data.heroImage
  const { currentPage } = pageContext

  return (
    <SEO
      title={`Newsroom` + ((currentPage > 1) ? ' (' + currentPage + ')' : '')}
      description={`Read the latest news, press releases and information from CICS.com and across UNICOM Global divisions` + ((currentPage > 1) ? ': page ' + currentPage : '.')}
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const tagPageQuery = graphql`
  query NewsPageItems($skip: Int!, $limit: Int!) {
    allContentfulNews(
        filter: { publishTo: { elemMatch: { url: { eq: "https://www.cics.com" } } } }
        sort: { fields: date, order: DESC }
        limit: $limit
        skip: $skip 
      ) {
        totalCount
        edges {
          node {
            slug
            title
            subtitle
            date
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, height: 300, quality: 90)
                }
              }
            }
          }
        }
      }
      featuredNews: allContentfulNews(
        filter: {
          featured: { elemMatch: { url: { eq: "https://www.cics.com" } } }
        }
        limit: 1
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            slug
            title
            subtitle
            date
            image: imageHero {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600, quality: 90)
                }
              }
              file {
                url
              }            
            }
          }
        }
      }
      heroImage: file(name: { eq: "news-default" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }      
      newspaper: file(name: { eq: "newspaper" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
`